<template>
  <li :class="classes.component">
    <base-image :image="image" />
    <!-- :sizes="sizes" -->
  </li>
</template>

<script>
import BaseImage from '@/components/base-image'

export default {
  components: {
    BaseImage
  },
  props: {
    image: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    imageAmount: {
      type: Number,
      required: true
    },
    hasPortraits: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      imageWidth: null
    }
  },
  computed: {
    classes() {
      return {
        component: [
          this.$style.component,
          this.$style['image-amount-' + this.imageAmount],
          this.$style['image-' + this.index],
          // necessary to use props to make sure it only renders portraits styles when BOTH images are portraits. Evaluating within this comp also relayouts img if one of the two is portrait
          this.hasPortraits ? this.$style.hasPortraits : '',
          this.isPortrait ? this.$style.isPortrait : this.$style.isLandscape
        ]
      }
    },
    isPortrait() {
      return this.image.ratio < 1
    },
    sizes() {
      let sizes = ''
      if (this.imageAmount === 1) {
        sizes = this.isPortrait
          ? '(min-width: 90em) 29vw, (min-width: 64em) 40vw, (min-width: 48em) 55.5vw, (min-width: 30em) 66vw, 62vw'
          : '(min-width: 90em) 66vw, (min-width: 64em) 77vw, (min-width: 48em) 71vw, (min-width: 30em) 66vw, 62vw'
      } else if (this.imageAmount === 2) {
        sizes = this.isPortrait
          ? '(min-width: 80em) 37vw, (min-width: 64em) 35vw, (min-width: 48em) 34vw, (min-width: 30em) 32vw, 29vw'
          : '(min-width: 48em) 40vw, 55vw'
      } else if (this.imageAmount === 3) {
        sizes =
          '(min-width: 80em) 37vw, (min-width: 64em) 35vw, (min-width: 48em) 26vw, (min-width: 30em) 32vw, 29vw'
        // if 4 images are present
      } else {
        sizes =
          '(min-width: 80em) 37vw, (min-width: 64em) 35vw, (min-width: 48em) 33vw, (min-width: 30em) 32vw, 29vw'
      }
      return sizes
    }
  }
}
</script>

<style lang="scss" module>
.component {
  width: 100%;
  max-width: 90vw;

  &.image-amount-1 {
    grid-row: 1 / 3;
    grid-column: 1 / 3;

    img {
      height: initial;
      max-height: initial;
      object-fit: initial;
    }

    // &.isPortrait {
    //   height: 100%;

    //   img {
    //     width: auto;
    //     height: 100%;
    //     object-fit: contain;
    //   }
    // }
  }

  &.image-amount-2 {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: 100%;
    }

    &.image-0 {
      grid-row: 1;
      grid-column: 1 / 3;
    }

    &.image-1 {
      grid-row: 2;
      grid-column: 1 / 3;
    }

    &.isLandscape {
      img {
        object-fit: cover;
      }
    }

    &.hasPortraits {
      &.image-0 {
        grid-row: 1 / 3;
        grid-column: 1;
      }

      &.image-1 {
        grid-row: 1 / 3;
        grid-column: 2;
      }
    }
  }

  &.image-amount-3 {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.image-0 {
      grid-row: 1;
      grid-column: 1;
    }

    &.image-1 {
      grid-row: 2;
      grid-column: 1;
    }

    &.image-2 {
      grid-row: 1 / 3;
      grid-column: 2;
    }
  }

  &.image-amount-4 {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &.image-0 {
      grid-row: 1;
      grid-column: 1;
    }

    &.image-1 {
      grid-row: 2;
      grid-column: 1;
    }

    &.image-2 {
      grid-row: 1;
      grid-column: 2;
    }

    &.image-3 {
      grid-row: 2;
      grid-column: 2;
    }
  }
}
</style>
