<template>
  <div :class="classes.component">
    <div ref="container" :class="classes.container" />
    <ul :class="classes.wrapper" :style="wrapperStyle">
      <repeater-matrix-type-image
        v-for="(image, index) in item.images"
        :key="`image-${index}`"
        :image="image"
        :index="index"
        :image-amount="item.images.length"
        :has-portraits="hasPortraits"
      />
      <div v-if="item.caption" v-html="item.caption" :class="classes.caption" />
    </ul>
    <transition name="t-section">
      <div v-if="showSectionTitle" :class="$style.section">
        <span v-html="item.sectionTitle" :class="$style.sectionTitle" />
      </div>
    </transition>
  </div>
</template>

<script>
import RepeaterMatrixTypeImage from '@/components/repeater-matrix-type-image.vue'
import { mapState } from 'vuex'

export default {
  components: { RepeaterMatrixTypeImage },
  props: {
    item: {
      type: Object,
      required: true
    },
    isCurrent: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      wrapperWidth: null
    }
  },
  computed: {
    ...mapState(['isMedium']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.isSinglePortrait ? this.$style.isSinglePortrait : '',
          this.hasMargin ? this.$style.hasMargin : '',
          this.hasMultipleImages ? this.$style.hasMultipleImages : ''
        ],
        container: [
          this.$style.container,
          this.hasLandscapes ? this.$style.hasLandscapes : '',
          this.hasImageCombo ? this.$style.hasImageCombo : ''
        ],
        wrapper: [
          this.$style.wrapper,
          this.hasMultipleImages ? this.$style.hasMultipleImages : '',
          this.hasPortraits ? this.$style.hasPortraits : '',
          this.hasLandscapes ? this.$style.hasLandscapes : ''
        ],
        caption: [
          this.$style.caption,
          this.isCurrent ? this.$style.isCurrent : ''
        ]
      }
    },
    isSinglePortrait() {
      if (this.hasMultipleImages) return
      return this.isPortrait(this.item.images[0])
    },
    // isSingleLandscape() {
    //   if (this.hasMultipleImages) return
    //   return !this.isPortrait(this.item.images[0])
    // },
    hasMultipleImages() {
      return this.item.images.length > 1
    },
    hasImageCombo() {
      return this.item.images.length > 2
    },
    calculateRatio() {
      if (!this.hasPortraits) return

      return this.item.images.reduce(
        (acc, image) => acc + parseFloat(image.ratio),
        0
      )
    },
    hasPortraits() {
      let portraits = []
      if (this.item.images.length !== 2) return
      this.item.images.map(image => {
        const isPortrait = this.isPortrait(image)
        isPortrait === true ? portraits.push(isPortrait) : false
      })
      return portraits.length === 2 ? true : false
    },
    // if image amount is 2 and at least one of them is a landscape image, return true
    hasLandscapes() {
      let landscapes = []
      if (this.item.images.length !== 2) return
      this.item.images.map(image => {
        const isLandscape = this.isLandscape(image)
        isLandscape === true ? landscapes.push(isLandscape) : false
      })
      return landscapes.length >= 1 ? true : false
    },
    showSectionTitle() {
      return this.isCurrent && this.item.sectionTitle
    },
    wrapperStyle() {
      return {
        width: this.wrapperWidth + 'px'
      }
    },
    hasMargin() {
      return this.item.withMargin > 0
    }
  },
  methods: {
    isLandscape(image) {
      return image.ratio > 1 ? true : false
    },
    isPortrait(image) {
      return image.ratio < 1 ? true : false
    },
    getWrapperWidth() {
      const container = this.$refs.container
      // get styles to subtract paddings, etc.
      // has to be done with getComputedStyle since there is no innerWidth on this element
      const containerStyles = window.getComputedStyle(container)
      const offsetTop = parseInt(containerStyles.paddingTop)
      const offsetBottom = parseInt(containerStyles.paddingBottom)
      const offsetLeft = parseInt(containerStyles.paddingLeft)
      const offsetRight = parseInt(containerStyles.paddingRight)

      // calculate actual container size without paddings, etc
      const containerHeight = container.offsetHeight - offsetTop - offsetBottom
      const containerWidth = container.offsetWidth - offsetLeft - offsetRight
      const cRatio = containerWidth / containerHeight

      let width = null
      this.item.images.forEach(image => {
        width =
          cRatio < image.ratio ? containerWidth : containerHeight * image.ratio
      })

      // if (this.isSinglePortrait) {
      //   width = width - 20
      // }

      // only applies for two images with both of them being portrait
      // if multliple images exist, use same calculation as for portraits
      if (this.hasPortraits) {
        width =
          cRatio < this.calculateRatio
            ? containerWidth
            : containerHeight * this.calculateRatio
      }

      if (this.hasMultipleImages && !this.hasPortraits) {
        if (this.item.images.length < 3) return
        width = containerWidth
      }

      this.wrapperWidth = width
    }
  },
  mounted() {
    setTimeout(() => {
      this.getWrapperWidth()
    }, 200)

    window.addEventListener('resize', this.getWrapperWidth)
  },
  activated() {
    this.getWrapperWidth()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWrapperWidth)
  }
}
</script>

<style lang="scss" scoped>
.t-section-enter-active,
.t-section-leave-active {
  transition: opacity var(--long) linear;
}

// to avoid flashing when there are two identicla section titles
.t-section-leave-active {
  transition-delay: var(--xshort);
}

.t-section-enter,
.t-section-leave-to {
  opacity: 0;
}
</style>

<style lang="scss" module>
.component {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: calc(var(--gutter) * 3.5) var(--gutter) calc(var(--gutter) * 4)
    var(--gutter);

  &.hasMultipleImages {
    height: 65%;
  }

  @media (min-width: $small) {
    padding: calc(var(--gutter) * 3.5) calc(var(--gutter) * 1.5)
      calc(var(--gutter) * 4) var(--gutter);

    &.hasMultipleImages {
      height: 100%;
    }
  }

  // overrides "default" padding for items declared in parent component
  @media (min-width: $medium) {
    padding: calc(var(--gutter) * 2.8) calc(var(--gutter) * 2)
      calc(var(--gutter) * 6) calc(var(--gutter) * 2);

    // make sure that single portraits are always centered, regardless of caption
    &.isSinglePortrait {
      justify-content: initial;
    }
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  width: 85vw;
  height: 100vh;
  padding: calc(var(--gutter) * 3.5) var(--gutter) calc(var(--gutter) * 4)
    var(--gutter);

  .hasMargin & {
    height: 50vh;
    // padding: calc(var(--gutter) * 8) calc(var(--gutter) * 2)
    //   calc(var(--gutter) * 15) var(--gutter);
  }

  // if image amount is 2 and at least one of them is a landscape image
  &.hasLandscapes {
    width: 55vw;
  }

  @media (min-width: $small) {
    &.hasLandscapes {
      width: 50vw;
    }
  }

  @media (min-width: $small) {
    padding: calc(var(--gutter) * 3.5) calc(var(--gutter) * 1.5)
      calc(var(--gutter) * 4) var(--gutter);
  }

  // overrides "default" padding for items declared in parent component
  @media (min-width: $medium) {
    width: 80vw;
    padding: calc(var(--gutter) * 2.8) calc(var(--gutter) * 2)
      calc(var(--gutter) * 6) calc(var(--gutter) * 2);

    .hasMargin & {
      height: 75vh;
      // padding: calc(var(--gutter) * 8) calc(var(--gutter) * 2)
      //   calc(var(--gutter) * 20) calc(var(--gutter) * 2);
    }

    // if image amount is 2 and at least one of them is a landscape image
    &.hasLandscapes {
      width: 40vw;
    }
  }

  @media (min-width: $xxlarge) {
    // set for image combos wih more than 2 images
    &.hasImageCombo {
      width: 65vw;
    }
  }
}

.wrapper {
  position: relative;
  display: grid;
  // based on: https://stackoverflow.com/questions/43311943/prevent-content-from-expanding-grid-items
  // and: https://stackoverflow.com/questions/52861086/why-does-minmax0-1fr-work-for-long-elements-while-1fr-doesnt
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-template-columns: repeat(2, 1fr);
  // only works without overflow when using fr units
  // see: https://css-tricks.com/introduction-fr-css-unit/
  grid-gap: var(--grid-gap);

  // if image amount is 2 and at least one of them is a landscape image
  &.hasLandscapes {
    width: 55vw;
  }

  @media (min-width: $small) {
    &.hasLandscapes {
      width: 50vw;
    }
  }

  @media (min-width: $medium) {
    &.hasLandscapes {
      width: 40vw;
    }
  }

  @media (min-width: $xxlarge) {
    &.hasLandscapes {
      width: 30vw;
    }
  }

  &.hasMultipleImages {
    height: 100%;

    &.hasPortraits {
      height: initial;
    }
  }
}

.section {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0 var(--gutter) var(--gutter) var(--gutter);
  // margin-bottom: -0.3em; // to align section title with lower border of cpation
}

.sectionTitle {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-medium;

  text-transform: lowercase;
}

.caption {
  @extend %ff-sans;
  @extend %fw-bold;
  @extend %fs-caption;

  grid-row: 3;
  grid-column: 1 / 3;
  align-self: end;
  max-width: 90vw; // apply same max-width as image has
  opacity: 0;
  transition: opacity var(--long) linear;

  &.isCurrent {
    opacity: 1;
  }

  @media (min-width: $medium) {
    position: absolute;
    bottom: calc(var(--grid-gap) * -1);
    transform: translateY(100%);
  }
}
</style>
